<route>
{
  "meta": {
    "auth": "userReviewPageBy"
  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20">
        <el-col :span="8">
          <span class="searchText">款式名称</span>
          <el-input class="searchInput" v-model.trim="searchValue.skuTitle" placeholder="款式名称" clearable>
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">商品名称</span>
          <el-input class="searchInput" v-model.trim="searchValue.productName" placeholder="商品名称" clearable>
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">用户昵称</span>
          <el-input class="searchInput" v-model.trim="searchValue.memberNickname" placeholder="用户昵称" clearable>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">订单编号</span>
          <el-input class="searchInput" v-model.trim="searchValue.orderId" placeholder="订单编号" clearable> </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">评价星级</span>
          <el-select class="searchInput" v-model="searchValue.allStar" placeholder="请选择星级">
            <el-option v-for="item in starOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8" v-if="$store.getters['user/isAllArea']">
          <span class="searchText">城市</span>
          <el-select class="searchInput" placeholder="请选择城市" @change="getList" v-model="areaId">
            <el-option label="全部" :value="-1"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal'].sort((a, b) =>
                a.areaFirstSpell > b.areaFirstSpell ? 1 : -1
              )"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="16">
          <span class="searchText">评价时间</span>
          <el-date-picker
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="time"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>评价列表</span>

        <el-button type="success" @click="downloadExcel()" style="float: right"> 导出评分</el-button>
      </div>
      <com-Item
        v-for="item in list"
        :key="item.id"
        :itemData="item"
        @showStar="showStar"
        @showDelete="showDelete"
        @check="check"
      ></com-Item>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="删除原因" :visible.sync="deleteDialog.show" width="350px">
      <el-input
        type="textarea"
        maxlength="30"
        show-word-limit
        placeholder="请输入删除原因"
        v-model.trim="deleteDialog.deleteReason"
        clearable
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialog.show = false">取 消</el-button>
        <el-button type="primary" :disabled="!deleteDialog.deleteReason" @click="handleStatus">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="导出订单" :visible.sync="download.show" append-to-body width="30%">
      <span style="display: inline-block; width: 60px"> 时间</span>
      <el-date-picker
        value-format="timestamp"
        v-model="download.time"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>

      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 用户昵称</span>
        <el-input placeholder="输入用户昵称" style="width: 300px" v-model="download.memberNickname"> </el-input>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 商品名称</span>
        <el-input placeholder="输入商品名称" style="width: 300px" v-model="download.productName"> </el-input>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 款式名称</span>
        <el-input placeholder="输入款式名称" style="width: 300px" v-model="download.skuTitle"> </el-input>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 评价星数</span>
        <el-select class="searchInput" v-model="download.allStar" placeholder="请选择星级">
          <el-option v-for="item in starOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownload">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import comItem from './__com__/item'
export default {
  name: 'productComment',
  mixins: [pagination],
  data() {
    return {
      areaId: -1,
      // 删除组件的时间戳

      time: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      searchValue: {
        memberNickname: '',
        skuTitle: '',
        allStar: '',
        endTime: '',
        productName: '',
        startTime: '',
        orderId: '' // 订单id
      },

      download: {
        show: false,
        url: '',
        time: [],
        allStar: '',
        skuTitle: '',
        productName: '',
        memberNickname: ''
      },
      list: [],
      starOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '一星'
        },
        {
          value: 2,
          label: '二星'
        },
        {
          value: 3,
          label: '三星'
        },
        {
          value: 4,
          label: '四星'
        },
        {
          value: 5,
          label: '五星'
        }
      ],
      deleteDialog: {
        id: '',
        show: false,
        deleteReason: '',
        showStatus: 0
      }
    }
  },
  components: {
    comItem
  },

  methods: {
    //==========================================================================下载Excel表格
    confirmDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : '',

            allStar: this.download.allStar,
            skuTitle: this.download.skuTitle,
            productName: this.download.productName,
            memberNickname: this.download.memberNickname
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    tableRowClassName({ row, rowIndex }) {
      // 优质评论高亮
      if (row.isStar) {
        return 'warning-row'
      }
      return ''
    },
    downloadExcel() {
      this.download = {
        status: '',
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/review',
        time: [],
        productId: '',
        allStar: '',
        skuTitle: '',
        productName: '',
        memberNickname: ''
      }
    },
    handleStatus() {
      this.$http
        .put(
          `boom-center-admin-service/sysAdmin/userReview/updateShowStatus?id=${this.deleteDialog.id}&showStatus=${
            this.deleteDialog.showStatus ? 0 : 1
          }`
        )
        .then(res => {
          this.$message.success('操作成功')
          this.getList(true)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    showDelete(scope) {
      this.deleteDialog.id = scope.row.id
      this.deleteDialog.showStatus = scope.row.showStatus
      let text = this.deleteDialog.showStatus ? '隐藏' : '显示'
      this.$confirm(`确定${text}该评价吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.handleStatus()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    handleStar(scope) {
      // 优质评价操作
      return Promise.resolve()
        .then(() => {
          if (scope.row.isStar) {
            return this.$http.put(`boom-center-admin-service/sysAdmin/userReview/unstar/${scope.row.id}`)
          } else {
            return this.$http.put(`boom-center-admin-service/sysAdmin/userReview/star/${scope.row.id}`)
          }
        })
        .then(res => {
          this.$message.success('操作成功')
          this.getList(true)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    showStar(scope) {
      // 更新优质评价
      let text = scope.row.isStar ? '取消' : '设为'
      this.$confirm(`确定${text}优质评价吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      })
        .then(() => {
          this.handleStar(scope)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },

    // 查看详情
    check(row) {
      this.$router.push({
        path: '/product/comment/particulars',
        query: {
          id: row.id
        }
      })
    },
    // 重置搜索
    resetSearch() {
      this.searchValue = {
        memberNickname: '',
        skuTitle: '',
        allStar: '',
        endTime: '',
        productName: '',
        startTime: ''
      }
      this.time = []
      this.getList()
    },
    // 搜索评论
    getList(val) {
      this.loading = true
      this.searchValue.page = val === true ? this.currentPage : 1
      this.searchValue.size = this.pageSize

      this.searchValue.startTime = undefined
      this.searchValue.endTime = undefined
      if (this.time) {
        this.searchValue.startTime = this.time[0]
        this.searchValue.endTime = this.time[1]
      }

      let params = {
        params: { ...this.searchValue }
      }
      this.$http
        .get('boom-mix-biz-service/sysAdmin/user/review/pageBy', {
          // 这里修改为聚合评论列表
          ...params,
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.list = res.list
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1 && this.$store.getters['user/userAreaInfoListWithoutGlobal']) {
      this.areaId = -1
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-table .warning-row {
  background: oldlace;
}
.info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.avatar {
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.commentInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.query {
  margin-top: 20px;
}
.tableContainer {
  max-width: none;
}
</style>
